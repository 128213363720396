import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';
import landingImage from '../../images/landing.png';
import { useTranslation, Trans } from 'react-i18next';

type Props = {};

const Hero: React.FC<Props> = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section className="hero">
      <div className="hero__text">
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={800} distance="30px">
          <h1 className="hero-title">
            {title || 'Hi, this is '}
            <span className="text-color-main">{name || 'WalkMeUp'}</span>
            <br />
            {subtitle || "I'm the Unknown Developer."}
            --{t('hero.title')}--
          </h1>
        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1200} distance="30px">
          <p className="hero-cta">
            <span className="cta-btn cta-btn--hero">
              <Link to="about" smooth duration={1000}>
                {cta || 'Know more'}
              </Link>
            </span>
          </p>
        </Fade>
      </div>
      <div className="hero__image">
        <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={400} distance="200px">
          <img className="hero-image" src={landingImage} alt="landing " />
        </Fade>
      </div>
    </section>
  );
};

Hero.defaultProps = {};

export default Hero;
