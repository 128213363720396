import React, { useState } from 'react';

import Logo from './Logo';
type Props = {};

const Header: React.FC<Props> = () => {
  const [active, setActive] = useState(false);

  const toggleActive = () => {
    setActive(!active);
  };
  return (
    <header className="header ">
      <Logo className="header__logo" />
      <button
        className={`hamburger hamburger--spring ${active ? 'is-active' : ''}`}
        type="button"
        onClick={() => {
          toggleActive();
        }}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
      <ul className="nav">
        <li className="nav__item">about</li>
        <li className="nav__item">donate</li>
        <li className="nav__item">contact</li>
      </ul>
    </header>
  );
};

export default Header;
