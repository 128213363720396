import React, { useState, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';

import Header from '@components/header/Header';
import Hero from '@components/Hero/Hero';
import About from './about/About';
// import Projects from './Projects/Projects';

import Contacts from './contacts/Contacts';
import Footer from './Footer/Footer';
import { PortfolioProvider } from '../context/context';
import i18next from '../i18n';

import { heroData, aboutData, projectsData, contactData, footerData } from '../mock/data';

function App() {
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [projects, setProjects]: [any, any] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setContact({ ...contactData });
    setFooter({ ...footerData });
  }, []);

  return (
    <I18nextProvider i18n={i18next}>
      <Header />

      <PortfolioProvider value={{ hero, about, projects, contact, footer }}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Hero />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <About />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Contacts />
            </div>
          </div>
          <Footer />
        </div>
      </PortfolioProvider>
    </I18nextProvider>
  );
}

export default App;
