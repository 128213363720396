import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';
// import PortfolioContext from '../../context/context';

const Footer = () => {
  // const { footer } = useContext(PortfolioContext);
  // const { networks } = footer;
  // const { isEnabled } = githubButtons;

  return <footer className="footer">FOOTER</footer>;
};

export default Footer;
